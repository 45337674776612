<script setup lang="ts">
import debounce from 'lodash/debounce.js'
import { useMembershipStore } from '~/stores/membership'
import IconDownload from '@/assets/icons/download.svg'
import IconLink from '@/assets/icons/link.svg'

const props = defineProps({
  isDownloading: {
    type: Boolean,
    required: true,
  },
  downloadProgress: {
    type: Number,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  isPresentation: {
    type: Boolean,
    default: false,
  },
  link: {
    type: String,
    default: '',
  },
})

const { $auth } = useNuxtApp()
const membershipStore = useMembershipStore()

const isAuthorized = computed(() => {
  return $auth.store.isAuthenticated && membershipStore.isSubscriberActive
})

const unAuthLabel = computed(() =>
  props.isPresentation && !isAuthorized.value ? 'Find out more' : props.label,
)

const emit = defineEmits(['onShareClick', 'onDownloadClick'])

const onDownloadClick = () => {
  emit('onDownloadClick')
  debouncedOverlayOptions()
}

const linkCopied = ref(false)
const debouncedCopyButton = debounce(() => {
  linkCopied.value = false
}, 1000)
const onShareClick = () => {
  navigator.clipboard.writeText(props.link)
  linkCopied.value = true
  debouncedCopyButton()
  debouncedOverlayOptions()
}

const copyButtonId = 'copy-button'

const showOverlayOptions = ref(false)
const debouncedOverlayOptions = debounce(() => {
  showOverlayOptions.value = false
}, 3000)
const openOverlayOptions = () => {
  showOverlayOptions.value = true
  debouncedOverlayOptions()
}
</script>

<template>
  <div class="absolute left-0 top-0 z-10 h-full w-full">
    <div
      class="absolute left-0 top-0 hidden h-full w-full items-center justify-center bg-black/80 text-white transition-opacity duration-500 md:flex md:group-hover:opacity-100"
      :class="{
        'opacity-0': !isDownloading,
        '!flex opacity-100': showOverlayOptions || isDownloading,
      }"
    >
      <div
        class="flex items-center justify-center"
        :class="{
          'mr-2 h-10 min-w-[112px] cursor-pointer border-r-1 border-white pr-2':
            props.isPresentation && isAuthorized,
        }"
        @click="onDownloadClick"
      >
        <CircularProgressBar
          v-if="isDownloading || downloadProgress"
          class="z-50"
          color="white"
          :progress="downloadProgress"
          :is-loading="isDownloading"
        />
        <p
          v-if="!isDownloading"
          class="flex h-full cursor-pointer items-center gap-1 text-base font-semibold md:mt-0"
        >
          <ClientOnly>
            {{ unAuthLabel }}
          </ClientOnly>
          <IconDownload v-if="isAuthorized" class="fill-white" />
        </p>
      </div>
      <div
        v-if="isPresentation && isAuthorized"
        :id="copyButtonId"
        class="flex cursor-pointer items-center gap-x-1"
        @click="onShareClick"
      >
        <div class="font-semibold">
          {{ linkCopied ? 'Copied!' : 'Share' }}
        </div>
        <IconLink v-if="isAuthorized" class="stroke-white" />
      </div>
    </div>

    <div
      v-if="!isDownloading"
      class="absolute flex h-full w-full cursor-pointer flex-col items-center justify-center md:hidden"
      :class="showOverlayOptions ? 'hidden' : 'flex'"
      @click="openOverlayOptions"
    >
      <div class="rounded-full bg-black/70 p-2">
        <IconDownload class="h-3 w-3 fill-white" />
      </div>
    </div>
  </div>
</template>
